import { Header } from '../Header';
import styles from './styles.module.css';
import error from '../../assets/svgs/error.svg';

export function SmallScreenPage() {
    return (
        <div className={styles.smallScreenPage}>
            <Header title={"BlazePod Leaderboard"} className={'smallScreen'} />

            <div className={styles.errorContainer}>
                <img className={styles.errorImage} alt="Error logo" src={error} />
                <div className={styles.errorTextWrapper}>
                    <div className={styles.errorTextTitle}>Leaderboards is not yet compatible with small screens.</div>
                    <div className={styles.errorTextSubtitle}>This device is too small to show our web leaderboards correctly.
                        Please open this link on a larger screen such as desktop or TV or head into your leaderboard inside your app.
                    </div>
                </div>
            </div>
        </div>
    )
}