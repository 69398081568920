import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { VideoPlayer } from "../VideoPlayer";
import { HasLabel } from "../../api/interfaces/has-label.interface";
import { SmallScreenPage } from "../SmallScreenPage";
import styles from "./styles.module.css";

interface ILayoutProps {
  children?: React.ReactNode;
}

export function Layout({ children }: ILayoutProps) {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const params = useParams<HasLabel>();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (windowSize < 1024) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [windowSize]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      label: params.label,
      action: "board_open",
    });
    return;
  }, [params.label]);

  useEffect(() => {
    const sendCloseEvent = () => {
      ReactGA.send({
        hitType: "pageview",
        label: params.label,
        action: "board_close",
      });
      return "";
    };
    window.addEventListener("beforeunload", sendCloseEvent);
    return () => {
      window.removeEventListener("beforeunload", sendCloseEvent);
    };
  }, [params.label]);

  const handleWindowResize = () => {
    setWindowSize(window.innerWidth);
  };

  const handleVisibilityChange = () => {
    ReactGA.send({
      hitType: "pageview",
      label: params.label,
      action: "background_view",
      is_hidden: document.hidden,
    });
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  });

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    window.addEventListener("orientationchange", handleWindowResize);
    return () => {
      window.removeEventListener("orientationchange", handleWindowResize);
    };
  });

  return (
    <>
      {isSmallScreen ? (
        <SmallScreenPage />
      ) : (
        <div className={styles.layout}>
          {children}
          <VideoPlayer url={"../../assets/blazepod-showcase.mp4"} />
        </div>
      )}
    </>
  );
}
