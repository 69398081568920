import React from "react";
import ReactPlayer from "react-player";
import styles from "./styles.module.css";

interface IVideoPlayer {
  url: string;
}

export function VideoPlayer({ url }: IVideoPlayer) {
  return (
    <div className={styles.videoContainer}>
      <ReactPlayer
        url={url}
        playing={true}
        muted={true}
        controls={false}
        loop={true}
        height={"100%"}
        width={"auto"}
      />
    </div>
  );
}
