import { HeaderLogos } from "../HeaderLogos";
import { HeaderTitles } from "../HeaderTitles";
import styles from '../Header/styles.module.css';

interface IHeaderProps {
  title: string;
  subtitle?: string;
  rightLogo?: string;
  className?: string;
}

export function Header({ title, subtitle = "", rightLogo, className}: IHeaderProps) {
  return (
    <header className={`${styles.header} ${className && styles[className]}`}>
      <HeaderTitles title={title} subtitle={subtitle} />
      <HeaderLogos rightLogo={rightLogo} />
    </header>
  );
}
