import styles from './styles.module.css';
import error from '../../assets/svgs/errorWhite.svg';

export function LabelNotFoundState() {
    return (
        <div className={styles.errorContainer}>
            <img className={styles.errorImage} alt="Error logo" src={error} />
            <div className={styles.errorTitle}>No blazing lights here...</div>
            <div className={styles.errorSubtitle}>Please check the leaderboard address has been <br/> entered correctly, or contact support for more help.</div>
        </div>
    )
}