import { ScoreMarkEnum } from "../../../api/enums/leaderboard-enums";
import firstPlaceIcon from '../../../assets/icons/firstPlaceIcon.svg';
import secondPlaceIcon from '../../../assets/icons/secondPlaceIcon.svg';
import thirdPlaceIcon from '../../../assets/icons/thirdPlaceIcon.svg';

export interface ApplyAnimationProps {
  applyAnimation: boolean;
  animationConfiguration: string;
  marks: ScoreMarkEnum[];
}

export const getAnimationDelay = (index: number) => `${0.4 + index / 20}s`;

export const trophyIconsMap:{[key: number]: string} = {
  0: firstPlaceIcon,
  1: secondPlaceIcon,
  2: thirdPlaceIcon
}