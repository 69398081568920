import { Header } from '../Header';
import { LabelNotFoundState } from '../LabelNotFoundState';
import styles from '../LeaderboardPage/styles.module.css';

export function NoLabelPage() {
    return (
        <div className={styles.leaderboardPage}>
            <Header title={"Invalid link"} />
            <LabelNotFoundState />
        </div>
    )
}