import styles from './styles.module.css';

interface IHeaderTitlesProps {
  title: string
  subtitle?: string
}

export function HeaderTitles({ title, subtitle }: IHeaderTitlesProps) {
  return (
    <div className={styles.titles}>
      <p className={styles.title}>{title}</p>
      { subtitle && <p className={styles.subtitle}>{subtitle}</p> }
    </div>
  );
}