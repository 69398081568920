import { useEffect, useMemo, useState } from "react";
import { MakeRow } from "./components/MakeRow";
import { ScoreMarkEnum } from "../../api/enums/leaderboard-enums";
import { BPLeaderboardItem } from "../../api/interfaces/BPleaderboard-item.interface";
import styles from "../TableData/styles.module.css";

interface ITableDataProps {
  size: number;
  items: BPLeaderboardItem[];
  marks: ScoreMarkEnum[];
}

export function TableData({size, items, marks}: ITableDataProps) {
  const animationConfiguration = `${styles.flash} 1.5s ease-in forwards`;
  const animationTimeout = 10000;
  const [applyAnimation, setApplyAnimation] = useState(false);

  useEffect(() => {
    setApplyAnimation(true);
    setTimeout(() => {
      setApplyAnimation(false);
    }, animationTimeout);
  }, [size, items, marks]);

  const emptyRowCount = useMemo(() => {
    return size - items.length;
  }, [items.length, size]);

  return (
    <div className={styles.table}>
      {items.map((item, index) => {
        return (
          <MakeRow
            key={index === 0 ? 'header_row' : `${index}_general_row`}
            animationConfiguration={animationConfiguration}
            applyAnimation={applyAnimation}
            item={item}
            marks={marks}
            index={index} />
        );
      })}

      {emptyRowCount > 0 &&
        [...Array(emptyRowCount)].map((irr, index) => {
          const rank = index + items.length;
          return (
            <MakeRow
              key={`${rank}_empty_row`}
              animationConfiguration={animationConfiguration}
              applyAnimation={applyAnimation}
              item={null}
              marks={marks}
              index={rank} />
        )})}
    </div>
  );
}
