import { useQuery } from "react-query";
import { HasLabel } from "../../api/interfaces/has-label.interface";
import { QueryKeys } from "../../enums";
import { useAppServices } from "../../hooks/useAppServices";
import { LeaderBoard } from "../LeaderBoard";
import ReactGA from "react-ga4";
import { useParams } from "react-router-dom";
import styles from "./styles.module.css";
import { LeaderboardLoadingState } from '../LoadingState';
import { LabelNotFoundState } from '../LabelNotFoundState';
import { EmptyLeaderboardState } from '../EmptyLeaderboardState';
import { useEffect, useState } from 'react';
import { Header } from '../Header';

export function LeaderboardPage() {
  const params = useParams<HasLabel>();
  const { leaderboardService } = useAppServices();
  const [headerTitle, setHeaderTitle] = useState('');

  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.Leaderboard,
    queryFn: () => {
      ReactGA.send({ hitType: "pageview", label: params.label, action: "screen_view" });
      return leaderboardService.getLeaderBoard(params.label ?? "")
    },
    refetchInterval: 30000
  });

  useEffect(() => {
    if(isLoading) {
      setHeaderTitle('Loading...');
    } else {
      if(data?.status.code === 404) {
        setHeaderTitle('Invalid link');
      } else if (data?.status && data?.status.code > 400) {
        setHeaderTitle('BlazePod Leaderboard');
      } else {
        setHeaderTitle(data?.payload.title || '');
      }
    }
  }, [isLoading, data])

  const renderLeaderboard = () => {
    if(isLoading) {
      return <LeaderboardLoadingState />
    }
    if(data?.status.code === 404) {
      return <LabelNotFoundState />
    }
    if (data?.status && data?.status.code > 400) {
      return <EmptyLeaderboardState />
    }

    return <LeaderBoard payload={data?.payload} />
  }

  return  (
    <div className={styles.leaderboardPage}>
      <Header title={headerTitle} subtitle={data?.payload?.subtitle} rightLogo={data?.payload?.rightLogo} />
      {renderLeaderboard()}
    </div>
  )
}