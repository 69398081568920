import { useEffect, useRef } from "react";
import { PlayerName } from "../../PlayerName";
import { ScoreResult } from "../../ScoreResult";
import { ApplyAnimationProps, getAnimationDelay, trophyIconsMap } from "./TableRowHelper";
import styles from "../styles.module.css";
import { BPLeaderboardItem } from '../../../api/interfaces/BPleaderboard-item.interface';

export interface IMakeRowProps extends ApplyAnimationProps {
  item?: BPLeaderboardItem | null;
  index: number;
}

export function MakeRow({item, marks, animationConfiguration, applyAnimation, index}: IMakeRowProps) {
  const elementRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.style.animation = applyAnimation ? animationConfiguration : '';
      element.style.animationDelay = applyAnimation ? getAnimationDelay(index) : '';
    }
  }, [animationConfiguration, applyAnimation, index]);

  const getRankImage = (index: number) => {
    if(trophyIconsMap[index]) {
      return <div className={styles.iconTrophyWrapper}>
        <img
            className={styles.iconTrophy}
            alt={`Trophy icon ${index + 1} place`}
            src={trophyIconsMap[index]}
        />
      </div>
    } else return <div>{index + 1}</div>
  }

  return (
    <div className={styles.leaderboardRowWrapper}>
      <div className={styles.rank}>{getRankImage(index)}</div>
      <div className={`${styles.leaderboardRow} ${!item && styles.leaderboardEmptyRow}`} ref={elementRef}>
        <PlayerName name={item ? item.name : ""} />
        <ScoreResult marks={marks} item={item} emptyString={!item ? '-' : undefined}/>
      </div>
    </div>
  );
}
