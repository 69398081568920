import styles from './styles.module.css'

export function EmptyLeaderboardState() {
  const errorMessage = 'Waiting for you to start an activity...';

    return (
        <div className={styles.errorContainer}>
            <p className={styles.errorText}>{ errorMessage }</p>
        </div>
    )
}
