import blazepod_big from '../../assets/svgs/blazepod-big.svg'
import styles from './styles.module.css';

interface IHeaderLogosProps {
  rightLogo?: string
}

export function HeaderLogos({rightLogo}: IHeaderLogosProps) {
  return (
    <div className={styles.logos}>
      <div className={styles.blazepodContainer1}>
        <img className={styles.blazepodIcon} alt='BlazePod logo' src={blazepod_big} />
      </div>

      {rightLogo &&
        <div className={styles.companyLogo}>
            <img className={styles.image11Icon} alt="Custom logo" src={rightLogo} />
        </div>
      }
    </div>
  );
}